import React from "react";
import { Router, Link } from "react-static";
import Routes from "react-static-routes";
import FooterMap from "components/FooterMap";

import "./app.scss";
import logoImg from "../resources/logo.png";

const App = () => (
  <Router>
    <React.Fragment>
      <nav className="navbar navbar-default">
        <div className="container-fluid">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#myNavbar"
            >
              <span className="icon-bar top-bar" />
              <span className="icon-bar middle-bar" />
              <span className="icon-bar bottom-bar" />
            </button>
            <Link className="navbar-brand" exact to="/">
              <img src={logoImg} />
            </Link>
          </div>
          <div className="collapse navbar-collapse" id="myNavbar">
            <ul className="nav navbar-nav">
              {/*<li>*/}
              {/*<Link exact to="/">*/}
              {/*Domů*/}
              {/*</Link>*/}
              {/*</li>*/}
              <li>
                <Link to="/sluzby">Geodetické služby</Link>
              </li>
              <li>
                <Link to="/o-nas">O nás</Link>
              </li>
              <li>
                <Link to="/nabidka-prace-v-geodezii">Nabídka práce</Link>
              </li>
              <li>
                <Link to="/kontakty">Kontakty</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="content-section">
        <Routes />
      </div>
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="footermenu">
              <div className="col-xs-6">
                <h5>
                  <Link to="/sluzby">Geodetické služby</Link>
                </h5>
                <ul>
                  <li>
                    <Link to="/sluzby/geometricky-plan">Geometrický plán</Link>
                  </li>
                  <li>
                    <Link to="/sluzby/zamereni-staveb-stavebni-vykresy">
                      Stavební výkresy
                    </Link>
                  </li>
                  <li>
                    <Link to="/sluzby/zamereni-staveb-stavebni-vykresy">
                      Zaměření staveb
                    </Link>
                  </li>
                  <li>
                    <Link to="/sluzby/zamereni-pozemku">Zaměření pozemků</Link>
                  </li>
                  <li>
                    <Link to="/sluzby/zamereni-bytovych-jednotek">
                      Zaměření byt/nebyt. jednotek
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-xs-6">
                <div id="onas">
                  <h5>
                    <Link to="/o-nas">O nás</Link>
                  </h5>
                  <ul>
                    <li>
                      <Link to="/nabidka-prace-v-geodezii">
                        Přidejte se do týmu
                      </Link>
                    </li>
                    <li>
                      <Link to="/kontakty#fakturacni-udaje">
                        Fakturační údaje
                      </Link>
                    </li>
                    <li>
                      <Link to="/kontakty#pobocky">Naše pobočky</Link>
                    </li>
                    <li>
                      <Link to="/kontakty">Kontakty</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xs-12" id="kontakt">
              <h5>Volejte kdykoliv:</h5>
              <ul>
                <li>
                  <a href="tel:+420777272854">777-272-854</a>
                </li>
              </ul>
              <h5>Nebo pište na:</h5>
              <ul>
                <li>
                  <a
                    href="mailto:info@foltanek.cz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@foltanek.cz
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <FooterMap />
      </footer>
    </React.Fragment>
  </Router>
);

export default App;
